<svelte:options accessors />

<script>
  import QiscusTriggerBtn from "./components/qiscus-trigger-btn.svelte";
  import { onMount } from "svelte";
  import aes from "crypto-js/aes";

  import {
    _ajax,
    generateDefaultConfig,
    setDefaultConfig,
    attachQismoCustomCSS,
    parseUrlParams,
    fadeIn,
    fadeOut,
    qiscusApp,
  } from "./helper.js";
  /*
    What's need to be done:
    - Load Qismo config from api
    - Render trigger button based on qismo config
    - Render Welcome message if any
    - Render Attention Grabber if any
    */

  let isInitiated = false;
  let isLogin = false;
  let defaultConfig = {};
  let isLoginFormOpened = false;
  let isWidgetOpened = false;
  let isMobile = false;
  let userData;
  let loginFormIframe;
  let welcomeMessageIframe;
  let attentionGrabberIframe;
  let widgetIframe;
  let customcss;
  let whitelabelcss = "";

  // widget channel
  let channelFormIframe;
  let isChannelFormOpened = false;
  let isFromChannelForm = false;
  let isFromPreviousForm = false;
  let breakpoint = null;

  export let staging = false;
  export let widgetDesktopSizes = [360, 510];
  export let loginFormSizes = [360, 510];
  export let channelFormSizes = [370, 515];
  export let welcomeMessageSizes = [380, 350];
  export let grabberSizes = [300, 150];

  const loadQismo = () => {
    defaultConfig = generateDefaultConfig(staging, options);
    _ajax({
      type: "GET",
      url: `${defaultConfig.qismoBaseUrl}/api/v2/app/config/public-widget/${appId}/${options.channel_id}`,
      appVersion: `${options.appVersion}`,
      successFn: function (res) {
        var data = res.data.widget;
        setDefaultConfig(data, options);
        customcss = attachQismoCustomCSS(data ? data.styles : null);
        renderWelcomeMessage();
        renderAttentionGrabber();
        var ls = JSON.parse(localStorage.getItem("qismo-widget"));
        if (ls && (!ls.display_name || !ls.unique_id))
          localStorage.removeItem("qismo-widget");
        if (qismoConfig.isChannelWidgetEnabled) {
          renderChannelForm();
        } else {
          if (!localStorage.getItem("qismo-widget")) {
            renderLoginForm();
          } else {
            login();
          }
        }
        isInitiated = true;
        setMobileView();
      },
      failFn: function (err) {},
    });
  };
  const renderAttentionGrabber = () => {
    if (qismoConfig.attentionGrabberStatus != true) return false;
    welcomeMessageIframe = document.createElement("iframe");
    let width = 300;
    let height = 150;
    let timeout = qismoConfig.attentionGrabberTimeout * 1000 || 0;
    let params = parseUrlParams({
      grabberImage: qismoConfig.grabberImage,
      grabberTextStatus: qismoConfig.grabberTextStatus,
      attention_grabber_image: qismoConfig.attentionGrabberImage,
      attention_grabber_text: qismoConfig.attentionGrabberText,
    });
    if (customcss) params += `&customcss=${customcss.replace(/#/g, "@")}`;
    let iframeSource = `${qismoConfig.qismoIframeUrl}/iframes/${appId}/attention-grabber${params}`;
    welcomeMessageIframe.setAttribute("src", iframeSource);
    welcomeMessageIframe.setAttribute("id", "qcw-welcome-iframe");
    welcomeMessageIframe.setAttribute("style", "display: none");
    changeIframeSize(
      welcomeMessageIframe,
      grabberSizes[0] + "px",
      grabberSizes[1] + "px",
      "0",
      "50px"
    );
    if (!document.getElementById("qcw-welcome-iframe"))
      document.body.appendChild(welcomeMessageIframe);
    setTimeout(() => fadeIn(welcomeMessageIframe), timeout);
  };
  const renderWelcomeMessage = () => {
    const ls = localStorage.getItem("qismo-widget");
    if (
      qismoConfig.welcomeMessageStatus != true ||
      qismoConfig.attentionGrabberStatus == true ||
      ls
    )
      return false;
    var timeout =
      qismoConfig.welcomeTimeout >= 0
        ? qismoConfig.welcomeTimeout
        : defaultConfig.welcomeTimeout;
    welcomeMessageIframe = document.createElement("iframe");
    let params = parseUrlParams({
      avatar_url: qismoConfig.customerServiceAvatar,
      cs_name: qismoConfig.customerServiceName,
      welcome_text: qismoConfig.welcomeText,
    });
    // if(customcss) params += `&customcss=${customcss.replace(/#/g,'@')}`;
    let iframeSource = `${qismoConfig.qismoIframeUrl}/iframes/${appId}/welcome-message${params}`;
    welcomeMessageIframe.setAttribute("src", iframeSource);
    welcomeMessageIframe.setAttribute("id", "qcw-welcome-iframe");
    welcomeMessageIframe.setAttribute("style", "display: none");
    changeIframeSize(
      welcomeMessageIframe,
      welcomeMessageSizes[0] + "px",
      welcomeMessageSizes[1] + "px",
      "0",
      "75px"
    );
    if (!document.getElementById("qcw-welcome-iframe"))
      document.body.appendChild(welcomeMessageIframe);
    setTimeout(function () {
      if (!isLoginFormOpened && !isChannelFormOpened && !isWidgetOpened) {
        welcomeMessageIframe.style.display = "block";
        fadeIn(welcomeMessageIframe);
        if (welcomeMessageIframe) {
          if (customcss)
            sendMessage("qcw-welcome-iframe", {
              event_name: "custom-css",
              css: customcss,
            });
          sendMessage("qcw-welcome-iframe", { event_name: "change-height" });
        }
      }
    }, parseInt(timeout) * 1000);
  };
  export const toggleLoginForm = () => {
    if (isLogin) return false;
    isLoginFormOpened = !isLoginFormOpened;
    const el = document.getElementById("qcw-login-form-iframe");
    if (!el) return false;
    if (isLoginFormOpened) {
      fadeIn(el, () => {
        setTimeout(() => {
          if (customcss)
            sendMessage("qcw-login-form-iframe", {
              event_name: "custom-css",
              css: customcss,
            });
          if (options && options.extra_fields)
            sendMessage("qcw-login-form-iframe", {
              event_name: "extra-fields",
              extra_fields: JSON.stringify(options.extra_fields),
            });
        }, 0);
      });
      closeWelcomeMessage();
    } else {
      fadeOut(el);
    }
  };
  export const toggleChannelForm = () => {
    if (isLogin && isWidgetOpened) return false;
    isChannelFormOpened = !isChannelFormOpened;
    const el = document.getElementById("qcw-channel-form-iframe");
    const widget = qismoConfig.channel_widget;
    if (!el) return false;
    if (isChannelFormOpened) {
      fadeIn(el, () => {
        setTimeout(() => {
          if (customcss)
            sendMessage("qcw-channel-form-iframe", {
              event_name: "custom-css",
              css: customcss,
            });
          if (widget)
            sendMessage("qcw-channel-form-iframe", {
              event_name: "widget",
              widget: JSON.stringify(widget),
            });
        }, 0);
      });
      closeWelcomeMessage();
    } else {
      fadeOut(el);
    }
  };
  export const toggleWidget = () => {
    if (
      qismoConfig.isChannelWidgetEnabled &&
      !isFromChannelForm &&
      channelFormIframe
    ) {
      toggleChannelForm();
    } else {
      if (!isLogin && !localStorage.getItem("qismo-widget")) {
        toggleLoginForm();
      } else {
        isWidgetOpened = !isWidgetOpened;
        toggleQiscusWidget();
      }
    }
    if (isWidgetOpened || isLoginFormOpened || isChannelFormOpened)
      closeWelcomeMessage();
  };
  export const openNextForm = () => {
    isChannelFormOpened = false;
    isFromChannelForm = true;
    isFromPreviousForm = false;
    if (!localStorage.getItem("qismo-widget")) {
      isLoginFormOpened = true;
      if (qiscusApp) qiscusApp.selectedNav = "login";
      if (loginFormIframe && typeof loginFormIframe == "object") {
        setTimeout(() => {
          fadeIn(loginFormIframe, () => {
            if (customcss)
              sendMessage("qcw-login-form-iframe", {
                event_name: "custom-css",
                css: customcss,
              });
            if (options && options.extra_fields)
              sendMessage("qcw-login-form-iframe", {
                event_name: "extra-fields",
                extra_fields: JSON.stringify(options.extra_fields),
              });
          });
        }, 1000);
      } else {
        renderLoginForm();
      }
    } else {
      isWidgetOpened = true;
      if (qiscusApp) qiscusApp.selectedNav = "chat";
      if (widgetIframe && typeof widgetIframe == "object") {
        window.postMessage({ event_name: "maximize-widget" });
        setTimeout(() => {
          fadeIn(widgetIframe, () => {
            if (!qismoConfig.widgetCustomCSS) return false;
            sendMessage("qcw-iframe", {
              event_name: "custom-css",
              css: qismoConfig.widgetCustomCSS,
            });
          });
        }, 1000);
      } else {
        let ls = JSON.parse(localStorage.getItem("qismo-widget"));
        userData = {
          display_name: ls.display_name,
          unique_id: ls.unique_id,
          extra_fields: ls.extra_fields,
        };
        renderQiscusWidget(true);
      }
    }

    fadeOut(channelFormIframe);
  };
  export const openPreviousForm = () => {
    isChannelFormOpened = true;
    isFromChannelForm = false;
    isFromPreviousForm = true;
    if (qiscusApp) qiscusApp.selectedNav = "channel";

    if (channelFormIframe && typeof channelFormIframe == "object") {
      const widget = qismoConfig.channel_widget;
      setTimeout(() => {
        fadeIn(channelFormIframe, () => {
          if (customcss)
            sendMessage("qcw-channel-form-iframe", {
              event_name: "custom-css",
              css: customcss,
            });
          if (widget)
            sendMessage("qcw-channel-form-iframe", {
              event_name: "widget",
              widget: JSON.stringify(widget),
            });
        });
      }, 1000);
    } else {
      renderChannelForm();
    }

    if (!localStorage.getItem("qismo-widget")) {
      isLoginFormOpened = false;
      fadeOut(loginFormIframe);
      setTimeout(() => {
        sendMessage("qcw-login-form-iframe", { event_name: "reset-form" });
      }, 500);
    } else {
      isWidgetOpened = false;
      window.postMessage({ event_name: "minimize-widget" });
      fadeOut(widgetIframe);
    }
  };
  export const login = (data) => {
    let ls = JSON.parse(localStorage.getItem("qismo-widget"));
    const isLoginFromForm = !ls ? true : false;
    if (ls) {
      userData = {
        display_name: ls.display_name,
        unique_id: ls.unique_id,
        extra_fields: ls.extra_fields,
      };
    } else {
      userData = data;
      if (options && options.extra_fields)
        userData.extra_fields = data.extra_fields;
      localStorage.setItem("qismo-widget", JSON.stringify(userData));
    }
    if (qiscusApp) qiscusApp.selectedNav = "chat";
    renderQiscusWidget(isLoginFromForm);
    isLogin = true;
    isLoginFormOpened = false;
    isChannelFormOpened = false;
    if (isLoginFromForm) {
      fadeOut(loginFormIframe);
    }
  };
  const toggleQiscusWidget = () => {
    const el = document.getElementById("qcw-iframe");
    if (!el) return false;
    if (isWidgetOpened) {
      window.postMessage({ event_name: "maximize-widget" });
      fadeIn(el, () => {
        if (!qismoConfig.widgetCustomCSS) return false;
        setTimeout(() => {
          sendMessage("qcw-iframe", {
            event_name: "custom-css",
            css: qismoConfig.widgetCustomCSS,
          });
        }, 1000);
      });
      closeWelcomeMessage();
    } else {
      window.postMessage({ event_name: "minimize-widget" });
      fadeOut(el);
    }
  };
  export const closeWelcomeMessage = () => {
    const el = document.getElementById("qcw-welcome-iframe");
    if (el) fadeOut(el);
  };
  var sendMessage = function (el, msg) {
    // Make sure you are sending a string, and to stringify JSON
    document.getElementById(el).contentWindow.postMessage(msg, "*");
  };
  const renderLoginForm = () => {
    loginFormIframe = document.createElement("iframe");
    const customerIdentifier = qismoConfig.customerIdentifierInputType
      ? qismoConfig.customerIdentifierInputType
      : qismoConfig.customerIdentifier || "email";
    let loginFormCSS = qismoConfig.loginFormCustomCSS || "";
    let params = parseUrlParams({
      formGreet: qismoConfig.formGreet,
      formSubtitle: qismoConfig.formSubtitle,
      buttonLabel: qismoConfig.loginFormButtonLabel,
      customerIdentifier: customerIdentifier,
      extra_fields:
        options && options.extra_fields
          ? JSON.stringify(options.extra_fields)
          : "",
      is_channel_widget_enable: qismoConfig.isChannelWidgetEnabled,
    });
    if (options && options.initialCountryCode)
      params += `&initialCountryCode=${options.initialCountryCode}`;
    if (options && options.whitelabel)
      whitelabelcss = `.qismo-copyright{display:none!important;}`;
    if (customcss) {
      customcss = customcss + loginFormCSS + whitelabelcss;
      params += `&customcss=${customcss.replace(/#/g, "@")}`;
    } else {
      params += `&customcss=${loginFormCSS + whitelabelcss}`;
    }
    let iframeSource = `${qismoConfig.qismoIframeUrl}/iframes/v4/${appId}/login-form${params}`;
    if (qismoConfig.loginFormUrl)
      iframeSource = `${qismoConfig.loginFormUrl}?customerIdentifier=${customerIdentifier}`;
    loginFormIframe.setAttribute("src", iframeSource);
    loginFormIframe.setAttribute("id", "qcw-login-form-iframe");
    loginFormIframe.setAttribute("style", "display: none");
    if (document.body.clientWidth < breakpoint) {
      changeMobileIframeSize(loginFormIframe);
    } else {
      changeIframeSize(
        loginFormIframe,
        loginFormSizes[0] + "px",
        loginFormSizes[1] + "px"
      );
    }
    if (!document.getElementById("qcw-login-form-iframe"))
      document.body.appendChild(loginFormIframe);
    if (isFromChannelForm) {
      setTimeout(() => {
        fadeIn(loginFormIframe, () => {
          if (customcss)
            sendMessage("qcw-login-form-iframe", {
              event_name: "custom-css",
              css: customcss,
            });
          if (options && options.extra_fields)
            sendMessage("qcw-login-form-iframe", {
              event_name: "extra-fields",
              extra_fields: JSON.stringify(options.extra_fields),
            });
        });
      }, 1000);
    } else {
      if (options && options.extra_fields)
        sendMessage("qcw-login-form-iframe", {
          event_name: "extra-fields",
          extra_fields: JSON.stringify(options.extra_fields),
        });
    }
    // if(customcss) sendMessage('qcw-login-form-iframe', { event_name: 'custom-css', css: customcss })
  };

  const renderChannelForm = () => {
    channelFormIframe = document.createElement("iframe");
    const widget = qismoConfig.channel_widget;
    let params = parseUrlParams({
      titleForm: widget ? widget.title : "Welcome",
      subtitleForm: widget
        ? widget.subtitle
        : "Send us via favourite messaging apps",
    });
    if (options && options.whitelabel)
      whitelabelcss = `.qismo-copyright{display:none!important;}`;
    // Get template channel widget form
    let iframeSource = `${qismoConfig.qismoIframeUrl}/iframes/v4/${appId}/widget-channel${params}`;
    channelFormIframe.setAttribute("src", iframeSource);
    channelFormIframe.setAttribute("id", "qcw-channel-form-iframe");
    channelFormIframe.setAttribute("style", "display: none");
    if (document.body.clientWidth < breakpoint) {
      changeMobileIframeSize(channelFormIframe);
    } else {
      changeIframeSize(
        channelFormIframe,
        channelFormSizes[0] + "px",
        channelFormSizes[1] + "px"
      );
    }
    if (!document.getElementById("qcw-channel-form-iframe"))
      document.body.appendChild(channelFormIframe);
    if (isFromPreviousForm || qismoConfig.openAtStart) {
      if (qismoConfig.openAtStart) isChannelFormOpened = true;
      setTimeout(() => {
        fadeIn(channelFormIframe, () => {
          if (customcss)
            sendMessage("qcw-channel-form-iframe", {
              event_name: "custom-css",
              css: customcss,
            });
          if (widget)
            sendMessage("qcw-channel-form-iframe", {
              event_name: "widget",
              widget: JSON.stringify(widget),
            });
        });
      }, 1000);
    } else {
      if (customcss)
        sendMessage("qcw-channel-form-iframe", {
          event_name: "custom-css",
          css: customcss,
        });
      if (widget)
        sendMessage("qcw-channel-form-iframe", {
          event_name: "widget",
          widget: JSON.stringify(widget),
        });
    }
  };

  export const attachCustomCSS = () => {
    var css = qismoConfig.widgetCustomCSS
      ? qismoConfig.widgetCustomCSS + whitelabelcss
      : whitelabelcss;
    sendMessage("qcw-iframe", { event_name: "custom-css", css: css });
  };

  const renderQiscusWidget = (isLoginFromForm) => {
    
    widgetIframe = document.createElement("iframe");
    let params = parseUrlParams({
      display_name: encodeURIComponent(aes.encrypt(userData.display_name.toString(), appId).toString()),
      channel_id: encodeURIComponent(aes.encrypt(options.channel_id.toString(), appId).toString()),
      unique_id: encodeURIComponent(aes.encrypt(userData.unique_id.toString(), appId).toString()),
      app_id: appId,
      staging,
    });

    if (userData.extra_fields) {
      params += `&extra_fields=${encodeURIComponent(userData.extra_fields)}`;
    }
    if (options && options.extras) params += `&extras=${options.extras}`;
    if (options && options.room_badge)
      params += `&room_badge=${options.room_badge}`;
    if (options && options.merge_properties)
      params += `&merge_properties=${options.merge_properties}`;
    if (qismoConfig.isChannelWidgetEnabled)
      params += `&is_channel_widget_enable=${qismoConfig.isChannelWidgetEnabled}`;
    // if(customcss) params += `&customcss=${customcss.replace(/#/g, '@')}`;
    let iframeSource = `${qismoConfig.qismoIframeUrl}/iframes/v4/${appId}/multichannel-widget/${options.channel_id}${params}`;
    widgetIframe.setAttribute("src", iframeSource);
    widgetIframe.setAttribute("id", "qcw-iframe");
    widgetIframe.setAttribute("style", "display: none");
    if (document.body.clientWidth < breakpoint) {
      changeMobileIframeSize(widgetIframe);
    } else {
      changeIframeSize(
        widgetIframe,
        widgetDesktopSizes[0] + "px",
        widgetDesktopSizes[1] + "px"
      );
    }
    if (options && options.whitelabel)
      whitelabelcss = `.qcw-copyright{display:none!important;}`;

    if (!document.getElementById("qcw-iframe"))
      document.body.appendChild(widgetIframe);
    if (qismoConfig.openAtStart || isLoginFromForm) {
      if (isLoginFromForm) isWidgetOpened = false;
      setTimeout(() => {
        fadeIn(widgetIframe, () => {
          var css = qismoConfig.widgetCustomCSS
            ? qismoConfig.widgetCustomCSS + whitelabelcss
            : whitelabelcss;
          sendMessage("qcw-iframe", { event_name: "custom-css", css: css });
        });
      }, 1000);
    } else {
      setTimeout(() => {
        var css = qismoConfig.widgetCustomCSS
          ? qismoConfig.widgetCustomCSS + whitelabelcss
          : whitelabelcss;
        sendMessage("qcw-iframe", { event_name: "custom-css", css: css });
      }, 1000);
    }
  };

  const changeIframeSize = (el, width, height, x, y) => {
    if (!el) return false;
    el.style.width = width;
    el.style.height = height;
    el.style.bottom = y ? y : "77px";
    el.style.right = x ? x : "42px";
  };
  const changeMobileIframeSize = (el) => {
    changeIframeSize(el, "100%", "100%");
    el.style.bottom = 0;
    el.style.right = 0;
  };

  const setMobileView = () => {
    breakpoint = qismoConfig.mobileBreakPoint
      ? qismoConfig.mobileBreakPoint
      : 400;
    if (document.body.clientWidth < breakpoint) {
      isMobile = true;
      if (widgetIframe) changeMobileIframeSize(widgetIframe);
      if (loginFormIframe) changeMobileIframeSize(loginFormIframe);
      if (channelFormIframe) changeMobileIframeSize(channelFormIframe);
    } else {
      isMobile = false;
      if (widgetIframe)
        changeIframeSize(
          widgetIframe,
          widgetDesktopSizes[0] + "px",
          widgetDesktopSizes[1] + "px"
        );
      if (loginFormIframe)
        changeIframeSize(
          loginFormIframe,
          loginFormSizes[0] + "px",
          loginFormSizes[1] + "px"
        );
      if (channelFormIframe)
        changeIframeSize(
          channelFormIframe,
          channelFormSizes[0] + "px",
          channelFormSizes[1] + "px"
        );
    }
  };
  onMount(async () => {
    loadQismo();
    window.addEventListener("resize", function (event) {
      setMobileView();
    });

    return () => {
      if (widgetIframe && typeof widgetIframe == "object") {
        document.body.removeChild(widgetIframe);
        widgetIframe = null;
      }
      if (loginFormIframe && typeof loginFormIframe == "object") {
        document.body.removeChild(loginFormIframe);
        loginFormIframe = null;
      }
      if (channelFormIframe && typeof channelFormIframe == "object") {
        document.body.removeChild(channelFormIframe);
        channelFormIframe = null;
      }
      if (welcomeMessageIframe && typeof welcomeMessageIframe == "object") {
        document.body.removeChild(welcomeMessageIframe);
        welcomeMessageIframe = null;
      }
    };
  });

  export let appId;
  export let options;
</script>

<main>
  {#if isInitiated}
    {#if (!isLoginFormOpened && !isChannelFormOpened && !isWidgetOpened && isMobile) || !isMobile}
      <QiscusTriggerBtn
        on:click={toggleWidget}
        {isMobile}
        label={qismoConfig.buttonText}
        buttonHasIcon={qismoConfig.buttonHasIcon}
        buttonHasText={qismoConfig.buttonHasText}
        icon={qismoConfig.buttonIcon}
      />
    {/if}
  {/if}
</main>

<style>
  :global(iframe#qcw-iframe, iframe#qcw-welcome-iframe, iframe#qcw-login-form-iframe, iframe#qcw-channel-form-iframe) {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    right: 42px;
    bottom: 87px;
    box-shadow: 0px 15px 25px rgba(189, 189, 189, 0.24);
    border-radius: 8px;
    z-index: 999999;
  }
  :global(iframe#qcw-welcome-iframe, iframe#qcw-channel-form-iframe) {
    right: 0;
    box-shadow: none;
  }
  .qiscus-widget-container {
    position: fixed;
    bottom: 15px;
    right: 15px;
    max-width: 360px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
