<script>
    export let label;
    export let icon;
    export let isMobile;
    export let buttonHasIcon;
    export let buttonHasText;
</script>

<div class='qcw-trigger-btn' on:click>
    {#if buttonHasIcon || isMobile}
        <img src={icon} alt="chat with us icon" height="20" />
    {/if}
    {#if buttonHasText && !isMobile}
    <div>{ (label) ? label : 'Chat With Us'}</div>
    {/if}
</div>

<style>
.qcw-trigger-btn {
    background: #74c162;
    color: #fff;
    padding: 15px 19px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    cursor: pointer;
    transition: all .3s ease;
    box-shadow: 0 7px 16px rgba(46, 46, 46, .15);
    border: 0;
    outline: 0;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    position: fixed;
    bottom: 20px;
    right: 42px;
    display: flex;
    z-index: 999999;
}
.qcw-trigger-btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 7px 16px rgba(46,46,46,.25);
}
.qcw-trigger-btn>div {
    margin-left: 15px;
}
</style>